import { createStyles, Card, TextInput, Text, Stack, Divider, Tabs, Group, Button, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import * as Realm from "realm-web";
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from '@mantine/core';
import { useBranches } from '../hooks/useBranches';
import { DropzoneButton } from '../components/DropZoneButton';
import { useListState } from '@mantine/hooks';
import { DndListHandle } from '../components/DndListHandle';
import { useProducts } from '../hooks/useProducts';


const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  title: {
    lineHeight: 1,
  },
}));

export function ProductEdit() {
  let { id } = useParams();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { save, getById, del } = useProducts();
  const [state, handlers] = useListState([]);
  const [edit, setEdit] = useState(false);

  const form = useForm({
    initialValues: {
      name: '',
      description: '', 
      _partition: 'PUBLIC',
      price: '0',
      price_package: '0',
      status: 'contact'
    },
  });

  useEffect(() => {
    getById(id).then(exh => {
      if(exh) setEdit(true);
      form.setValues(exh);
      if(exh && exh.image) handlers.setState([exh.image])
    });
  }, [id])

  const addNewHall = async (values) => {
    setVisible(true);
    await save(
      {
        ...values, 
        _id: new Realm.BSON.ObjectID(),
        price: new Realm.BSON.Double(values.price),
        price_package: new Realm.BSON.Double(values.price_package),
        image: state.length ? state[0] : ''
      }
    )
    navigate('/products');
  }

  const deleteBranch = () => {
    del(id);
    navigate('/products');
  }


  return (
    <div style={{position: 'relative'}}>
    <Card withBorder radius="md" p="xl" className={classes.card}>
      <form onSubmit={form.onSubmit(addNewHall)}>

      <LoadingOverlay visible={visible} overlayBlur={2} />

      <Text size="xl" className={classes.title} weight={'bold'}>
        Салбар нэмэх
      </Text>
      <Divider my="sm" />
      <Stack>
        { state.length > 0 && <DndListHandle state={state} handlers={handlers} /> }
        <DropzoneButton state={state} handlers={handlers}/>

        <TextInput
          placeholder="Нэр"
          label="Нэр"
          withAsterisk
          {...form.getInputProps('name')}
        />

        <TextInput
          placeholder="Тайлбар"
          label="Тайлбар"
          withAsterisk
          {...form.getInputProps('description')}
        />

        <TextInput
          placeholder="Үнэ 1"
          label="Үнэ 1"
          type="number"
          withAsterisk
          {...form.getInputProps('price')}
        />

        <TextInput
          placeholder="Үнэ 2"
          label="Үнэ 2"
          type="number"
          withAsterisk
          {...form.getInputProps('price_package')}
        />

        <Divider my="sm" />

        <Button type='submit'>
          Хадгалах
        </Button>

       {edit && <Button color="red" onClick={deleteBranch}> 
          Устгах
        </Button>}

      </Stack>
    </form>
    </Card>
    </div>
  );
}