import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";
import * as Realm from "realm-web";

import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getItemIndex,
} from "./utils";

export function useQuality() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [qualities, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const userCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "khutul_prod",
    collection: "QualityDoc",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    userCollection.find().then((fetchedusers) => {
        setUsers(fetchedusers);
      setLoading(false);
    });
  }, [userCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(userCollection, {
    onInsert: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx =
          getItemIndex(oldusers, change.fullDocument) ?? oldusers.length;
        if (idx === oldusers.length) {
          return addValueAtIndex(oldusers, idx, change.fullDocument);
        } else {
          return oldusers;
        }
      });
    },
    onUpdate: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx = getItemIndex(oldusers, change.fullDocument);
        return updateValueAtIndex(oldusers, idx, () => {
          return change.fullDocument;
        });
      });
    },
    onReplace: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx = getItemIndex(oldusers, change.fullDocument);
        return replaceValueAtIndex(oldusers, idx, change.fullDocument);
      });
    },
    onDelete: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx = getItemIndex(oldusers, { _id: change.documentKey._id });
        if (idx >= 0) {
          return removeValueAtIndex(oldusers, idx);
        } else {
          return oldusers;
        }
      });
    },
  });

  const save = async (draft) => {
    try {
      draft.created_at = new Date();
      await userCollection.updateOne({_id: draft._id}, draft, { upsert: true });
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a exhibition multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  const getById = async (id) => {
    const current = await userCollection.findOne({_id: new Realm.BSON.ObjectID(id)})
    return current
  }

  const del = async (id) => {
    await userCollection.deleteOne({_id: new Realm.BSON.ObjectID(id)});
  }

  return {
    loading,
    qualities,
    save,
    getById,
    del
  };
}
