import { Table, ActionIcon, Group, Skeleton, Anchor, Image } from '@mantine/core';
import { useBranches } from '../hooks/useBranches';
import { useQuality } from '../hooks/useQuality';
import * as dayjs from 'dayjs'
import { IconPencil, IconTrash } from '@tabler/icons';
import { Link } from 'react-router-dom';


export default function QualityDocs() {
  const { loading, qualities } = useQuality();;
  
  const rows = qualities.map((element)  => {
    return (
      <tr key={element._id.toString()}>
        <td>
          {dayjs(element.date).format('YYYY-MM-DD')}
        </td>
        <td>
          <Image
            height={300}
            width={200}
            fit="contain"
            radius="md"
            src={element.image_url}
          />
        </td>
        <td>
          <Group spacing={0} position="right">
            <Link to={`/qualities/${element._id.toString()}`}>
              <ActionIcon>
                <IconPencil size={16} stroke={1.5} />
              </ActionIcon>
            </Link>
          </Group>
        </td>
      </tr>
    )
  });
  
  const rowsLoading = Array(10).fill(0).map((element, index)  => {
    return (
      <tr key={index}>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
        <td>
          <Skeleton height={20} radius="xs" />
        </td>
      </tr>
    )
  });

  return (
    <Table>
      <thead>
          <tr>
            <th>Огноо</th>
            <th>Файл</th>
            <th></th>
          </tr>
      </thead>
      <tbody>
        {loading ? rowsLoading : rows}
      </tbody>
    </Table>
  );
  }