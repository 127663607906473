import { useState } from "react";

import { RealmAppProvider, useRealmApp } from "./api/RealmApp";
import realmJson from "./api/realm.json";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core';


import Root from "./routes/Root";
import ErrorPage from "./routes/ErrorPage";
import Users from "./routes/Users";
import { AuthenticationTitle } from "./routes/Auth";
import Products from "./routes/Products";
import Branches from "./routes/Branches";
import Orders from "./routes/Orders";
import QualityDocs from "./routes/QualityDocs";
import { BranchEdit } from "./routes/BranchEdit";
import { ProductEdit } from "./routes/ProductEdit";
import { QualityEdit } from "./routes/QualityEdit";
import Banners from "./routes/Banners";
import { BannerEdit } from "./routes/BannerEdit";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "users",
        element: <Users></Users>,
      },
      {
        path: "products",
        element: <Products></Products>
      },
      {
        path: "products/new",
        element: <ProductEdit></ProductEdit>
      },
      {
        path: "products/:id",
        element: <ProductEdit></ProductEdit>
      },
      {
        path: "branches",
        element: <Branches></Branches>
      },
      {
        path: "branches/new",
        element: <BranchEdit></BranchEdit>
      },
      {
        path: "branches/:id",
        element: <BranchEdit></BranchEdit>
      },
      {
        path: "orders",
        element: <Orders></Orders>
      },
      {
        path: "qualities",
        element: <QualityDocs></QualityDocs>
      },
      {
        path: "qualities/new",
        element: <QualityEdit></QualityEdit>
      },
      {
        path: "qualities/:id",
        element: <QualityEdit></QualityEdit>
      },
      {
        path: "banners",
        element: <Banners></Banners>
      },
      {
        path: "banners/new",
        element: <BannerEdit></BannerEdit>
      },
      {
        path: "banners/:id",
        element: <BannerEdit></BannerEdit>
      },
    ],
  },
  {
    path: "/login",
    element: <AuthenticationTitle />
  }
]);

export default function AppWithRealm() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>('dark');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <RealmAppProvider appId={realmJson.appId}>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme
          }}>
          <RouterProvider router={router} />
        </MantineProvider>
      </ColorSchemeProvider>
    </RealmAppProvider>
  );
}
