import React from "react";
import { useWatch } from "./useWatch";
import { useCollection } from "./useCollection";
import { useRealmApp } from "../api/RealmApp";
import realmJson from "../api/realm.json";

import {
  addValueAtIndex,
  replaceValueAtIndex,
  updateValueAtIndex,
  removeValueAtIndex,
  getItemIndex,
} from "./utils";

export function useOrders() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [orders, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const userCollection = useCollection({
    cluster: realmJson.dataSourceName,
    db: "khutul_prod",
    collection: "Order",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  React.useEffect(() => {
    userCollection.find().then((fetchedusers) => {
        setUsers(fetchedusers);
      setLoading(false);
    });
  }, [userCollection]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(userCollection, {
    onInsert: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx =
          getItemIndex(oldusers, change.fullDocument) ?? oldusers.length;
        if (idx === oldusers.length) {
          return addValueAtIndex(oldusers, idx, change.fullDocument);
        } else {
          return oldusers;
        }
      });
    },
    onUpdate: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx = getItemIndex(oldusers, change.fullDocument);
        return updateValueAtIndex(oldusers, idx, () => {
          return change.fullDocument;
        });
      });
    },
    onReplace: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx = getItemIndex(oldusers, change.fullDocument);
        return replaceValueAtIndex(oldusers, idx, change.fullDocument);
      });
    },
    onDelete: (change) => {
        setUsers((oldusers) => {
        if (loading) {
          return oldusers;
        }
        const idx = getItemIndex(oldusers, { _id: change.documentKey._id });
        if (idx >= 0) {
          return removeValueAtIndex(oldusers, idx);
        } else {
          return oldusers;
        }
      });
    },
  });

  return {
    loading,
    orders,
  };
}
